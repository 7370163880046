<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">
          <div class="flex flex-wrap align-items">
            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">订单编号：</span>
              <div class="width200">
                <el-input
                  v-model="searchData.orderCode"
                  placeholder="输入订单编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">下单时间：</span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">下单人：</span>
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入会员编号/会员昵称/会员手机号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">区域：</span>
              <div class="width200">
                <el-input
                  v-model="searchData.regionName"
                  placeholder="输入区域名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">车型：</span>
              <div class="width200">
                <el-input
                  v-model="searchData.carTypeName"
                  placeholder="输入车型名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">支付渠道：</span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.payType"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="微信"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="余额"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom20">
              <span class="span marginRight10">订单状态：</span>
              <div class="width250">
                <el-select
                  class="width250"
                  v-model="searchData.states"
                  multiple
                  collapse-tags
                  placeholder="请选择（可多选）"
                  @change="handleSearch"
                >
                  <el-option
                    :value="1"
                    label="待支付"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="进行中"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="已完成"
                  ></el-option>
                  <el-option
                    :value="4"
                    label="售后中"
                  ></el-option>
                  <el-option
                    :value="5"
                    label="已关闭-正常关闭"
                  ></el-option>
                  <el-option
                    :value="6"
                    label="已关闭-拒绝售后"
                  ></el-option>
                  <el-option
                    :value="7"
                    label="已关闭-退款成功"
                  ></el-option>
                  <el-option
                    :value="8"
                    label="已关闭-换车成功"
                  ></el-option>
                  <el-option
                    :value="9"
                    label="已关闭-超时未支付"
                  ></el-option>
                  <el-option
                    :value="10"
                    label="已关闭-手动取消"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom20">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom20"
              >查询</el-button>
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom20"
              >重置</el-button>
              <el-button
                size="medium"
                type="warning"
                icon="el-icon-download"
                @click="handleExport"
                class="marginLeft10 marginBottom20"
              >导出</el-button>
            </div>
          </div>
        </div>
      </template>
      <template
        slot="retailScale"
        slot-scope="{ row }"
      >
        <div>{{ row.retailScale + "%" }}</div>
      </template>
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleFinish(row)"
          v-if="row.state === 1 || row.state === 2"
        >结束订单</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleDetail(row)"
        >查看详情</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleLocus(row)"
          v-if="row.state !== 1 && row.state !== 9 && row.state !== 10"
        >车辆行驶轨迹</el-button>
      </template>
      <template
        slot="payPrice"
        slot-scope="{ row }"
      >
        <div>{{  row.totalPrice ? (row.totalPrice - row.depositPrice - row.refundPrice - row.memberPrice - row.couponPrice ).toFixed(2) : ''   }}</div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { manageList, manageOver } from "@/api/order/index";
import qs from "qs";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      disabled: false,
      searchData: {
        carTypeName: "",
        name: "",
        orderCode: "",
        payType: "",
        regionName: "",
        states: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "订单列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: true,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "订单编号",
            prop: "orderCode",
          },
          {
            label: "会员编号",
            prop: "code",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "区域名称",
            prop: "regionName",
          },
          {
            label: "车型名称",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
          },
          {
            label: "订单金额(元)",
            prop: "totalPrice",
          },
          {
            label: "押金金额(元)",
            prop: "depositPrice",
          },
          {
            label: "实付金额(元)",
            prop: "payPrice"
          },
          {
            label: "支付渠道",
            prop: "payType",
            formatter: function (row, column, cellValue) {
              return  row.payType === 1
                ? "微信"
                : row.payType === 2
                ? "余额"
                : row.payType;
            },
          },
          {
            label: "下单时间",
            prop: "createTime",
          },
          {
            label: "开锁时间",
            prop: "openTime",
          },
          {
            label: "归还时间",
            prop: "returnTime",
          },
          {
            label: "订单状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "待支付"
                :  row.state === 2
                ? "进行中"
                :  row.state === 3
                ? "已完成"
                :  row.state === 4
                ? "售后中"
                :  row.state === 5
                ? "已关闭-正常关闭"
                :  row.state === 6
                ? "已关闭-拒绝售后"
                :  row.state === 7
                ? "已关闭-退款成功"
                :  row.state === 8
                ? "已关闭-换车成功"
                :  row.state === 9
                ? "已关闭-超时未支付"
                :  row.state === 10
                ? "已关闭-手动取消"
                :  row.state;
            },
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.createTimeStart &&
        searchForm.searchData.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData.createTimeStart,
          searchForm.searchData.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 结束订单
    handleFinish(row) {
      this.$confirm("确定结束订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        manageOver({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("结束订单成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 查看详情
    handleDetail(row) {
      this.$router.push({
        path: "/order/manage/detail",
        query: {
          id: row.id,
        },
      });
    },
    // 车辆行驶轨迹
    handleLocus(row) {
      this.$router.push({
        path: "/order/manage/look",
        query: {
          id: row.id,
        },
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      this.searchData = {
        carTypeName: "",
        name: "",
        orderCode: "",
        payType: "",
        regionName: "",
        states: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        carTypeName: this.searchData.carTypeName,
        name: this.searchData.name,
        orderCode: this.searchData.orderCode,
        payType: this.searchData.payType,
        regionName: this.searchData.regionName,
        states: this.searchData.states,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      manageList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 导出
    handleExport() {
      const searchData = {
        carTypeName: this.searchData.carTypeName,
        name: this.searchData.name,
        orderCode: this.searchData.orderCode,
        payType: this.searchData.payType,
        regionName: this.searchData.regionName,
        states: this.searchData.states,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/order/manage/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>
